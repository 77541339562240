import React, { Component } from 'react';
import axios from 'axios';
import { Link } from '@curi/react-dom';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	getCookie,
	getErrorMessage,
	setCookie
} from './Utils';

export default class SMSNotifications extends Component {

	constructor(props) {

		super(props);

		this.catchError = this.catchError.bind(this);
		this.getFileList = this.getFileList.bind(this);
		this.endGetFileListConnection = this.endGetFileListConnection.bind(this);
		this.endUploadConnection = this.endUploadConnection.bind(this);
		this.logOut = this.logOut.bind(this);
		this.SelectFile = this.SelectFile.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);
		this.Upload = this.Upload.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
		this.state = {
			FileSelected: false,
			isLoading: false,
      list: [],
			Progress: 0,
			showMessage: false,
      VisibleInput: ''
		};

		this.KeepChecking = true;

	}

  componentDidMount() {

		this.getFileList();

  }

	render() {

		document.body.style.backgroundColor = '#BA491C';

		var Options = {};

    if(this.state.isLoading) {

        Options['disabled'] = 'disabled';

    }

		var borderRadius = '0px';

		if(this.state.Progress === 100) {

			borderRadius = '7px'

		}

		return(

			<div className='largeBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='Tabs'>
					<Link
						className='unselectedTab'
						name="Applications">
						Laekunud avaldused
					</Link>
					<Link
						className='unselectedTab'
						name="AdministrationTool">
						Administreerimine
					</Link>
					<Link
						className='unselectedTab'
						name="Archive">
						Arhiiv
					</Link>
					<Link
						className='unselectedTab'
						name="Reports">
						Raportid
					</Link>
					<div id='SelectedTab'>SMS teavitused<p></p></div>
				</div>
				<div id='AdminApplicationsBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center'>
									<h2>SMS teavituste saatmine</h2>
								</td>
								<td className='TopButtons'>
									<button {...Options} type='button' className='StandardButton' onClick={this.logOut}>Logi välja</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='InnerBox'>
            <p></p>
						<div id='Upload'>
              <form onSubmit={this.Upload}>
                <div>
                  <span id='UploadBox'>
                    <input
                      {...Options}
                      accept='.csv'
                      type='file'
                      name='File'
                      id='UploadInput'
                      onChange={this.SelectFile.bind(this)}>
                    </input>
                    <div id='UploadCover'>
                      <input value={this.state.VisibleInput} readOnly></input>
                      <button id='UploadButton'>Vali fail</button>
                    </div>
                  </span>
                  {this.state.FileSelected ? <button {...Options} type='submit' className='StandardButton'>Lae üles</button> : null}
                  {this.state.FileSelected ?
                    <div>
                      <div id='ProgressBar'>
                        <div id='Progress' style={{width: this.state.Progress + '%', borderTopRightRadius: borderRadius, borderBottomRightRadius: borderRadius}}></div>
                      </div>
                      {this.state.Progress}%
                    </div>
                    : null}
                </div>
              </form>
              <p></p>
              {this.state.list.length > 0 ?
                <table className='width75percent marginAuto'>
                  <thead>
                    <tr>
                      <th className='Width80'>ID</th>
                      <th className='WidthRemaining'>Faili nimi</th>
                      <th className='Width170'>Laadimise aeg</th>
                      <th className='Width80'>Ridu</th>
                      <th className='Width80'>Teavitatud</th>
                      <th className='Width80'>Vigased</th>
                      <th className='Width80'>Ootel</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.list.map(function(list, i) {
                    return(
                      <tr className='backgroundWhite' key={i}>
                        <td className='padding5px'>{list.id}</td>
                        <td className='TextLeft padding5px'>{list.name}</td>
                        <td>{list.create_dtime}</td>
                        <td className='padding5px'>
                          {list.count > 0 ?
                            <Link
                              className='linkLikeText'
                              name="NotificationsList"
                              params={{
                                listId: list.id,
                                status: 'koik'
                              }}>
                              {list.count}
                          </Link> : list.count}
                        </td>
                        <td className='padding5px'>
                          {list.sent > 0 ?
                            <Link
                              className='linkLikeText'
                              name="NotificationsList"
                              params={{
                                listId: list.id,
                                status: 'teavitatud'
                              }}>
                              {list.sent}
                          </Link> : list.sent}
                        </td>
                        <td className='padding5px'>
                          {list.error > 0 ?
                            <Link
                              className='linkLikeText'
                              name="NotificationsList"
                              params={{
                                listId: list.id,
                                status: 'vigased'
                              }}>
                              {list.error}
                          </Link> : list.error}
                        </td>
                        <td className='padding5px'>
                          {list.pending > 0 ?
                            <Link
                              className='linkLikeText'
                              name="NotificationsList"
                              params={{
                                listId: list.id,
                                status: 'ootel'
                              }}>
                              {list.pending}
                          </Link> : list.pending}
                        </td>
                      </tr>
                    );
                  }, this)}
                  </tbody>
                </table> : null}
              <table>
              </table>
						</div>
					</div>
				</div>
			</div>

		);

	}

	catchError(error) {

		this.message.push(getErrorMessage(this.props.router, this.props.response.name, error));

		this.setState({
			isLoading: false,
			progress: 0,
			showMessage: true
		});

	}

	endGetFileListConnection(response) {

		if(window.location.pathname === '/sms_teavitused') {

      this.setState({list: response.data.data});

			setTimeout(this.getFileList, 10000);

		}

	}

	endUploadConnection(Response) {

		this.message.push([
			{
				value: 'Laetud!',
				align: 'center'
			}
		]);

		this.setState({
			isLoading: false,
			showMessage: true
		});

	}

	getFileList() {

		if(this.KeepChecking) {

			var URL = this.APIURL + 'notification/sms';

			axios.get(URL, this.config).then(this.endGetFileListConnection).catch(this.catchError);

		}

	}

	logOut() {

		setCookie('AHFLevel', getCookie('AHFLevel'), -1);
		setCookie('AHFToken', getCookie('AHFToken'), -1);
		setCookie('AHFUser', getCookie('AHFToken'), -1);

    let url = this.props.router.url({ name: 'Login' });

    this.props.router.navigate({ url });

	}

	SelectFile(event) {

    let value = event.target.value;

    let i = value.lastIndexOf('\\') + 1;

    if(i !== -1) {

      value = value.substring(i, value.length);

    }

		if(value != null) {

			this.setState({
        FileSelected: true,
        Progress: 0,
        VisibleInput: value
      });

		}

		else {

			this.setState({
        FileSelected: false,
        Progress: 0,
        VisibleInput: ''
      });

		}

	}

	setShowMessage(showMessage) {

		this.setState({showMessage: showMessage});

	}

	Upload(event) {

    event.preventDefault();

		var errorMessage = [];

		if(errorMessage.length === 0) {

			this.setState({ isLoading: true });

			var URL = this.APIURL + 'notification/sms';

			var data = new FormData();

			data.append('file', event.target.File.files[0].name);

			data.append('file', event.target.File.files[0]);

			var config = {
				headers: {'Content-Type': 'application/binary', 'Pragma': 'no-cache'},
				timeout: 600000,
				auth: {username: getCookie('AHFToken')},
				onUploadProgress: function (progressEvent) {

					var progress = Math.round(progressEvent.loaded / progressEvent.total * 100);

					if(progressEvent.total > 0) {

						this.setState({Progress: progress});

					}

				}.bind(this),
			};

			axios.post(URL, data, config).then(this.endUploadConnection).catch(this.catchError);

		}

		else {

			this.message.push(errorMessage);

			this.setState({ showMessage: true });

		}

	}

}