import React, { Component } from 'react';
import axios from 'axios';

import LoadingOverlay from './LoadingOverlay';
import ShowMessage from './ShowMessage';
import {
	catchError,
	getCookie
} from './Utils';

export default class NotificationsList extends Component {

	constructor(props) {

		super(props);

		this.back = this.back.bind(this);
		this.catchError = catchError.bind(this);
		this.endGetFileListConnection = this.endGetFileListConnection.bind(this);
		this.endGetNotificationsListConnection = this.endGetNotificationsListConnection.bind(this);
		this.setShowMessage = this.setShowMessage.bind(this);

		this.APIURL = require('./../config/config.js').APIURL;
		this.config = {
			headers: {'Content-Type': 'application/json', 'Pragma': 'no-cache'},
			timeout: 30000,
			auth: {username: getCookie('AHFToken')},
		};
		this.message = [];
    this.list = [];
		this.state = {
      fileCreatedDTime: '',
      fileName: '',
			isLoading: true,
      list: [],
			showMessages: false
		};

	}

	componentDidMount() {

    let ending = '';

    if(this.props.response.params.status === 'teavitatud') {
      ending = '/S';
    }
    else if(this.props.response.params.status === 'vigased') {
      ending = '/E';
    }
    else if(this.props.response.params.status === 'ootel') {
      ending = '/P';
    }

		var URL = this.APIURL + 'notification/sms/' + this.props.response.params.listId + ending;

		axios.get(URL, this.config).then(this.endGetNotificationsListConnection).catch(this.catchError);

	}

	render() {

		document.body.style.backgroundColor = '#BA491C';

    let headerType = '';

    if(this.props.response.params.status === 'teavitatud') {
      headerType = ' saadetud';
    }
    else if(this.props.response.params.status === 'vigased') {
      headerType = ' vigaste';
    }
    else if(this.props.response.params.status === 'ootel') {
      headerType = ' ootel';
    }

		return(

			<div id='applicationsBoxWrapper'>
				<LoadingOverlay isLoading={this.state.isLoading}/>
				<ShowMessage
					showMessage={this.state.showMessage}
					message={this.message}
					setShowMessage={this.setShowMessage}
				/>
				<div id='ApplicationsBox' className='OuterBox'>
					<table className='MaxWidth'>
						<tbody>
							<tr>
								<td className='Logo'>
									<img src='/img/logo.gif' alt=''></img>
								</td>
								<td className='Center' colSpan='2'>
									<h2>Faili{headerType} ridade nimekiri</h2>
								</td>
								<td className='TopButtons'>
									<button type='button' className='StandardButton' onClick={this.back}>Tagasi</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className='Header'>
						<table className='MaxWidth'>
							<tbody>
								<tr>
									<td>
										<p></p>
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Faili nimi:
									</td>
									<td className='Content'>
                    {this.state.fileName}
									</td>
								</tr>
								<tr>
									<td className='Label'>
										Laadimise aeg:
									</td>
									<td className='Content'>
										{this.state.fileCreatedDTime}
									</td>
								</tr>
								<tr>
									<td>
										<p></p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div>
						<p></p>
						<div className='InnerBox'>
            {this.state.list.length === 0 ? <div className='textCenter'>Andmeid lei leitud!</div> :
							<table id='ApplicationsTable'>
								<thead>
									<tr>
										<th className='Width60'>Rida</th>
										<th className='Width120'>Nimi</th>
										<th className='Width100'>Telefon</th>
										<th className='WidthRemaining'>Teavituse tekst</th>
										{this.props.response.params.status === 'koik' ? <th className='Width60'>Olek</th> : null}
										{this.props.response.params.status === 'vigased' || this.props.response.params.status === 'koik' ? <th className='Width250'>Vea kirjeldus</th> : null}
									</tr>
								</thead>
								<tbody>
                  {this.state.list.map(function(list, i) {
                    let status = 'Saadetud';
                    if(list.status === 'E') {
                      status = 'Veaga';
                    }
                    else if(list.status === 'P') {
                      status = 'Ootel';
                    }
                    return(
                      <tr className='backgroundWhite' key={i}>
                        <td className='textCenter padding5px'>{list.row_num}</td>
                        <td className='TextLeft padding5px'>{list.name}</td>
                        <td className='TextLeft padding5px'>{list.phone}</td>
                        <td className='TextLeft padding5px'>{list.sms_text}</td>
                        {this.props.response.params.status === 'koik' ? <td className='textCenter padding5px'>{status}</td> : null}
                        {this.props.response.params.status === 'vigased' || this.props.response.params.status === 'koik' ? <td className='TextLeft padding5px'>{list.error_text}</td> : null}
                      </tr>
                    );
                }, this)}
								</tbody>
							</table>}
						</div>
					</div>
				</div>
			</div>

		);

	}

	back() {

    let url = this.props.router.url({ name: 'SMSNotifications' });

    this.props.router.navigate({ url });

	}

	endGetFileListConnection(response) {

    let fileName = '';

    let fileCreatedDTime = '';

    for(let i = 0; i < response.data.data.length; i++) {

      if(this.list.lenght > 0 && this.list[0].id === response.data.data[i].id) {

        fileName = response.data.data[i].name;

        fileCreatedDTime = response.data.data[i].create_dtime;

        break;

      }

    }

		this.setState({
      fileCreatedDTime: fileCreatedDTime,
      fileName: fileName,
			isLoading: false
    });

	}

	endGetNotificationsListConnection(response) {

    this.list = response.data.data;

		this.setState({list: response.data.data});

    let URL = this.APIURL + 'notification/sms';

    axios.get(URL, this.config).then(this.endGetFileListConnection).catch(this.catchError);

	}

	setShowMessage(showMessage) {

		if(!showMessage) {

			if(this.navigateBackAfterMessage) {

				this.back();

			}

			else {

				this.setState({showMessage: false});

			}

		}

		else {

			this.setState({showMessage: true});

		}

	}

}